import { render, staticRenderFns } from "./BuyBoxFilter.vue?vue&type=template&id=7428de61&scoped=true"
import script from "./BuyBoxFilter.vue?vue&type=script&lang=ts"
export * from "./BuyBoxFilter.vue?vue&type=script&lang=ts"
import style0 from "./BuyBoxFilter.vue?vue&type=style&index=0&id=7428de61&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7428de61",
  null
  
)

export default component.exports