
import { namespace } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';

import { SnowplowContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { OfferPresentationType, OfferPresentationTypes } from '@/interfaces/titles';

import { ModalHelper } from '@/helpers/modal-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import ChipButton from '@/ui-components/ChipButton.vue';
import ChipButtons from '@/ui-components/ChipButtons.vue';
import CountrySwitcherModal from '@/components/buybox/country-switcher/CountrySwitcherModal.vue';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';

const Language = namespace('language');
const User = namespace('user');

@Component({
	name: 'BuyBoxFilter',
	components: {
		ChipButton,
		ChipButtons,
	},
})
export default class BuyBoxFilter extends Vue {
	@Prop({ default: false }) isInModal: boolean;
	@Prop({ default: null }) defaultPresentationType: OfferPresentationType | null;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	// NEW_BUYBOX_TV_SHOW_EXP_V1_2
	@Prop({ default: false }) isShowExpEpisodeBuyBox: boolean;
	@Prop({ default: false }) isPopup: boolean;
	@Prop({ default: null }) episodeBuyBoxPresentationType: OfferPresentationType | null;
	// NEW_BUYBOX_TV_SHOW_EXP_v1_2

	@Language.State webLocale: string;
	@Language.Getter countryEmoji: string;

	@User.Getter buyboxPresentationType: OfferPresentationType;
	@User.Action setBuyboxPresentationType: (payload: {
		presentationType: OfferPresentationType | null;
		additionalContexts?: SnowplowContext[];
	}) => void;

	get filterOptions() {
		const options = [
			{
				label: this.$t('WEBAPP_QUALITY_BESTPRICE'),
				value: null,
				isQuality: false,
			},
			{
				label: this.$t('WEBAPP_BUYBOX_FREE_FILTER'),
				value: OfferPresentationType.FREE,
				isQuality: false,
			},
		] as const;

		const qualityOptions = OfferPresentationTypes.filter(g => g.isQuality);
		return options.concat(qualityOptions as any[]);
	}

	openCountrySwitcherModal() {
		ModalHelper.openModal(CountrySwitcherModal, { closable: true });
		TrackingHelper.trackEvent('userinteraction', { action: 'country_selector_clicked' }, this.additionalContexts);

		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'country_selector_clicked' });
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
	}

	handleFilterClick(payload: {
		presentationType: OfferPresentationType | null;
		additionalContexts?: SnowplowContext[];
	}) {
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		this.isShowExpEpisodeBuyBox
			? this.$emit('episodeFilterChange', payload.presentationType)
			: this.setBuyboxPresentationType(payload);

		const labelPrefix = this.isPopup ? 'filter_popup_toggled' : 'filter_toggled';

		trackTitleDetailRedesignNewBuyBoxExp({
			action: 'click',
			label: payload.presentationType ? `${labelPrefix}_${payload.presentationType}` : `${labelPrefix}_all`,
		});
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
	}

	mounted() {
		// for Apple TV+ search ads
		if (this.$route.query.monetization_types === 'free') {
			return this.setBuyboxPresentationType({ presentationType: OfferPresentationType.FREE });
		}

		if (!this.defaultPresentationType) return;

		this.setBuyboxPresentationType({
			presentationType: this.defaultPresentationType,
			additionalContexts: this.additionalContexts,
		});
	}
}
