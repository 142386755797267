
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SearchBar extends Vue {
	@Prop({ type: String, default: '' }) value!: string;
	@Prop({ required: true }) placeholder!: string;
	@Prop({ default: 0 }) debounce: number;
	@Prop({ default: true }) clearable: boolean;
	@Prop({ default: 'dark' }) theme: 'dark' | 'light';
	searchQuery = '';
	timeout: NodeJS.Timeout | null = null;

	icons = {
		search: faSearch,
		cross: faTimes,
	};

	@Emit('search')
	private emitSearchEvent() {
		return this.searchQuery;
	}

	@Emit('focus')
	onFocus(event: Event) {
		return event;
	}

	mounted() {
		// Set the initial value of the search query
		this.searchQuery = this.value;
	}

	clearSearchQuery() {
		this.searchQuery = '';
		this.emitSearchEvent();
	}

	updateSearchQuery(event: Event) {
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.searchQuery = (event.target as HTMLInputElement).value.trim();
			this.emitSearchEvent();
		}, this.debounce);
	}
}
