
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BasicButton from '@/ui-components/BasicButton.vue';

import BaseModal from '@/components/modals/BaseModal.vue';
import CountryList from '@/components/buybox/country-switcher/CountryList.vue';
import { SnowplowContext, SnowplowModuleContext } from '@/helpers/tracking/providers';

@Component({
	name: 'CountrySwitcherModal',
	components: {
		BaseModal,
		BasicButton,
		CountryList,
	},
})
export default class CountrySwitcherModal extends Vue {
	@Prop({ default: null }) countrySelectedCallback: () => void | null;
	@Prop({ default: () => [] }) additionalContexts: (SnowplowModuleContext | SnowplowContext)[];

	async closeSelf() {
		const { modalController } = await import('@/ionic.loader');

		await this.countrySelectedCallback?.();
		modalController.dismiss();
	}

	get modalTitle() {
		return this.$t('WEBAPP_ACCOUNTMODAL_MAIN_COUNTRY');
	}
}
