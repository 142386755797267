import { computed, UnwrapRef } from 'vue';
import { toValue, type MaybeRefOrGetter } from '@vueuse/core';

import { OfferPresentationType } from '@/interfaces/titles';
import type { TitleDetail } from '@/interfaces/title-details-graphql';
import { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';

import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';

import {
	type CatalogueItem,
	getRawCatalogue,
	type PromotionOffer,
	FALLBACK_PROMOTION_OFFER,
} from '@/helpers/promotion-helper';

interface UsePromotionOptions {
	offers: MaybeRefOrGetter<PromotionOffer[]>;
	title: MaybeRefOrGetter<TitleDetail>;
}

export type UsePromotion = UnwrapRef<ReturnType<typeof usePromotion>>;

/** Only until we get the remaining translations. */
export const allowedPromotionLanguages = ['es', 'en', 'de', 'fr', 'pt', 'it'];

export function usePromotion({ offers, title }: UsePromotionOptions) {
	const { language, country } = useLanguageStore();
	const { isPremium, buyboxPresentationType } = useUserStore();

	const offersToCheck = computed(() => toValue(offers));

	const showPromotion = computed(
		() =>
			!isPremium.value &&
			buyboxPresentationType.value === OfferPresentationType.FREE &&
			allowedPromotionLanguages.includes(language.value)
	);

	const offerFromPromotionCatalogueProvider = computed(() => {
		// iterate through the ordered promotionCatalogue
		const firstMatchingPackageId = Array.from(promotionCatalogue.value.keys()).find(key =>
			offersToCheck.value.some(offer => offer.package.packageId === key)
		);

		return offersToCheck.value.find(offer => firstMatchingPackageId === offer.package.packageId) ?? null;
	});

	const promotionCatalogue = computed(() => {
		const titleValue = toValue(title);
		const catalogue = getRawCatalogue();

		// We need the insertion order for offer priority
		return new Map<number, CatalogueItem>(
			catalogue
				.sort(
					(a, b) =>
						b.fallBackPriority(country.value, titleValue) - a.fallBackPriority(country.value, titleValue)
				)
				.filter(item => item.include(country.value, titleValue))
				.map(item => [item.packageId, item])
		);
	});

	const catalogueItem = computed(() => {
		if (offerFromPromotionCatalogueProvider.value) {
			return promotionCatalogue.value.get(offerFromPromotionCatalogueProvider.value.package.packageId) ?? null;
		}
		// Iterator would never be `done` because Apple TV+ is the final fallback and always in the catalogue
		const { done, value } = promotionCatalogue.value.values().next();
		if (!done) return value;

		return null;
	});

	const promotionOffer = computed<TitleOfferFragment | null>(() => {
		if (catalogueItem.value) {
			const catalogue = catalogueItem.value.offer(offerFromPromotionCatalogueProvider.value, country.value);
			if (
				catalogue.id === FALLBACK_PROMOTION_OFFER &&
				catalogueItem.value.fallBackExcludedCountries.has(country.value)
			) {
				return null;
			}
			return catalogue;
		}

		return offerFromPromotionCatalogueProvider.value! as TitleOfferFragment;
	});

	return {
		showPromotion,
		catalogueItem,
		promotionOffer,
		promotionCatalogue,
		inPromotionCatalogue: computed(() => offerFromPromotionCatalogueProvider.value !== null),
	};
}
