import type { TitleDetail } from '@/interfaces/title-details-graphql';
import { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';

import { OfferPresentationType } from '@/interfaces/titles';

type PromotionType = 'FREE_TRIAL' | 'PROMOTION';

export interface PromotionOffer {
	package: {
		clearName: string;
		packageId: number;
	};
}
export const FALLBACK_PROMOTION_OFFER = 'PROMOTION_OFFER' as const;

export interface CatalogueItem {
	packageId: number;
	clearName: string;
	technicalName: string;
	countries: Set<string>;
	type: PromotionType;
	fallback: string;
	fallbackByCountry?: { [countryCode: string]: string };
	fallBackExcludedCountries: Set<string>;
	offer: (offer: PromotionOffer | null, country: string) => TitleOfferFragment;
	conditions: (title: TitleDetail) => boolean;
	fallBackPriority: (country: string, title: TitleDetail) => number;
	include: (country: string, title: TitleDetail) => boolean;
	clickOutLinkRewrite?: (link: string, country: string, inCatalogue: boolean) => string;
}

function withFallback(
	offer: PromotionOffer | null,
	country: string,
	{ clearName, packageId, fallback, fallbackByCountry }: CatalogueItem
) {
	if (offer) {
		// The actual Apple TV+ package has the clearName "Apple TV Plus"
		offer.package.clearName = clearName;
		return offer as TitleOfferFragment;
	}
	const standardWebURL = fallbackByCountry && fallbackByCountry[country] ? fallbackByCountry[country] : fallback;

	// Minumum properties needed for tracking and PromotionRow
	return {
		id: FALLBACK_PROMOTION_OFFER,
		monetizationType: OfferPresentationType.FREE,
		presentationType: 'hd',
		package: { clearName, packageId },
		standardWebURL,
	} as unknown as TitleOfferFragment;
}

export const geoBlockedCountriesFreeTrialAppleFallback = [
	'VN',
	'LK',
	'KH',
	'MO',
	'LA',
	'TJ',
	'SZ',
	'KN',
	'AE',
	'SA',
	'UA',
	'EG',
	'BG',
	'KR',
	'QA',
	'EE',
	'MT',
	'MD',
	'LB',
	'BY',
	'FJ',
	'BM',
	'KY',
	'MZ',
	'NE',
	'JO',
	'NI',
	'PA',
	'LV',
	'FI',
	'RU',
	'SK',
	// 'PE',
	'HN',
	'TW',
	'TT',
	'OM',
];

/** Catalogue of Promotions in order of decreasing priority. */
//@ts-ignore
export const getRawCatalogue: () => Array<CatalogueItem> = () => [
	{
		/* Apple TV+ */
		packageId: 350,
		clearName: 'Apple TV+',
		technicalName: 'appletvplus',
		type: 'FREE_TRIAL',
		countries: new Set(),
		fallBackExcludedCountries: new Set([
			'PH',
			'ID',
			'MY',
			'TH',
			'MU',
			// 'DK',
			'CR',
			'PY',
			'BO',
			'ZA',
			'SG',
			'GH',
			'ZW',
			...geoBlockedCountriesFreeTrialAppleFallback,
		]),
		fallback: 'https://tv.apple.com/channel/tvs.sbd.4000?at=1000l3V2&ct=free_trial&itscg=30200',
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include: () => true,
		fallBackPriority: () => 0,
	},
	{
		/* Amazon Prime Video */
		packageId: 9,
		clearName: 'Amazon Prime Video',
		technicalName: 'amazonprime',
		type: 'FREE_TRIAL',
		countries: new Set(['GB', 'DE']),
		fallBackExcludedCountries: new Set(),
		fallback: 'https://www.amazon.de/gp/video/primesignup?tag=free_trial-21',
		fallbackByCountry: {
			//DE: 'https://www.amazon.de/gp/video/primesignup?tag=free_trial-21',
		},
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority(country: string) {
			return !!this.fallbackByCountry?.[country] ? 1 : 0;
		},
		clickOutLinkRewrite: amazonLinkRewrite,
	},
	{
		/* Amazon Prime Video */
		packageId: 119,
		clearName: 'Amazon Prime Video',
		technicalName: 'amazonprimevideo',
		type: 'FREE_TRIAL',
		countries: new Set(['ES', 'IT', 'FR', 'PT', 'CO', 'AR', 'DK', 'CR', 'PY', 'BO']),
		fallBackExcludedCountries: new Set(),
		fallback: 'http://primevideo-eu.pxf.io/B095Wy',
		fallbackByCountry: {
			//AR: 'http://primevideo-row.pxf.io/WqzOjA',
			//CO: 'http://primevideo-row.pxf.io/WqzOjA',
			// PT: 'http://primevideo-eu.pxf.io/B095Wy',
			// IT: 'https://www.primevideo.com/?&tag=free_trial03-21',
			// DK: 'http://primevideo-eu.pxf.io/B095Wy',
			CR: 'http://primevideo-row.pxf.io/WqzOjA',
			PY: 'http://primevideo-row.pxf.io/WqzOjA',
			BO: 'http://primevideo-row.pxf.io/WqzOjA',
			// FR: 'https://www.primevideo.com/?tag=free_trial02-21',
		},
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority(country: string) {
			return !!this.fallbackByCountry?.[country] ? 1 : 0;
		},
		clickOutLinkRewrite: amazonLinkRewrite,
	},
	{
		/* Paramount+ Amazon Channel */
		packageId: 582,
		clearName: 'Paramount+ Amazon Channel',
		technicalName: 'amazonparamountplus',
		type: 'FREE_TRIAL',
		countries: new Set(['DE', 'GB', 'IT', 'FR']),
		fallBackExcludedCountries: new Set(),
		fallback: '',
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority: () => 0,
		clickOutLinkRewrite: amazonLinkRewrite,
	},
	{
		/* MGM Plus Amazon Channel */
		packageId: 588,
		clearName: 'MGM Plus Amazon Channel',
		technicalName: 'amazonmgmplus',
		type: 'FREE_TRIAL',
		countries: new Set(['DE', 'FR', 'IT', 'GB', 'ES']),
		fallBackExcludedCountries: new Set(),
		fallback: '',
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority: () => 0,
		clickOutLinkRewrite: amazonLinkRewrite,
	},
	{
		/* FlixOlé Amazon Channel */
		packageId: 684,
		clearName: 'FlixOlé Amazon Channel',
		technicalName: 'amazonflixole',
		type: 'FREE_TRIAL',
		countries: new Set(['ES']),
		fallBackExcludedCountries: new Set(),
		fallback: '',
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority: () => 0,
		clickOutLinkRewrite: amazonLinkRewrite,
	},
	{
		/* Hulu */
		packageId: 15,
		clearName: 'Hulu',
		technicalName: 'hulu',
		type: 'FREE_TRIAL',
		countries: new Set(['US']),
		fallBackExcludedCountries: new Set(),
		fallback: 'https://www.kmtrak.com/23H7P9/3HWS9F7/?sub1=free_trial',
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority: () => 0,
	},
	{
		/* Crunchyroll */
		packageId: 283,
		clearName: 'Crunchyroll',
		technicalName: 'crunchyroll',
		type: 'FREE_TRIAL',
		countries: new Set(['AU', 'GB']),
		fallBackExcludedCountries: new Set(),
		fallback: 'https://crunchyroll.pxf.io/m5v45a',
		offer: function (offer: PromotionOffer | null, country: string) {
			return withFallback(offer, country, this);
		},
		conditions: () => true,
		include(country: string, title: TitleDetail) {
			return this.countries.has(country) && this.conditions(title);
		},
		fallBackPriority: (_, title: TitleDetail) => {
			return title?.content?.genres?.some(el => el.shortName === 'ani') ? 2 : 0;
		},
	},
];

const amazonTagByCountry = {
	DE: 'movie0c6-21',
	IT: 'justwatch07-21',
	FR: 'just03b-21',
	ES: 'just0a7-21',
	GB: 'just016-21',
};

export function amazonLinkRewrite(link: string, country: string, inCatalogue: boolean) {
	let overwrittenLink = link;
	const tag = amazonTagByCountry[country as keyof typeof amazonTagByCountry];

	if (overwrittenLink && inCatalogue) {
		if (tag) overwrittenLink = link + '&linkCode=xm2' + '&tag=' + tag;
	}
	return overwrittenLink;
}
