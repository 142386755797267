
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { WebLocale } from '@/enums/web-locale';

@Component({ name: 'CountryListItem' })
export default class CountryListItem extends Vue {
	@Prop({ required: true }) webLocale: WebLocale;
	@Prop({ type: Boolean }) selected: boolean;
	@Prop({ type: Boolean }) excluded: boolean;

	get classNames() {
		return {
			'country-list-item': true,
			'country-list-item--selected': this.selected,
			'country-list-item--excluded': this.excluded,
		};
	}

	get icon() {
		if (this.excluded) {
			return 'minus-circle';
		}

		if (this.selected) {
			return 'jw-check-icon';
		}
	}

	get src() {
		return `${JW_CONFIG.ASSETS_URL}/flags/${this.webLocale.toUpperCase()}.png`;
	}
}
