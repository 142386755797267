
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Route } from 'vue-router';

import BuyBoxFilter from '@/components/buybox/BuyBoxFilter.vue';
import BuyBoxRow from '@/components/buybox/BuyBoxRow.vue';
import {
	GetTitleOffersDocument,
	GetTitleOffersQuery,
	GetTitleOffersQueryVariables,
} from '@/components/buybox/graphql/queries/GetTitleOffers.query';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';

const OfferReporter = () => import('@/components/buybox/OfferReporter.vue');
const BuyBoxFastRow = () => import('@/components/buybox/BuyBoxFastRow.vue');
const BuyBoxNoOfferRow = () => import('@/components/buybox/BuyBoxNoOfferRow.vue');
const PromotionRow = () => import('@/components/buybox/PromotionRow.vue');
// APPLE_QUALTIY_TV_EXP
const AppleQualityTvRow = () => import('@/components/buybox/AppleQualityTvRow/AppleQualityTvRow.vue');
// APPLE_QUALTIY_TV_EXP
import { ImpressionTrackingEvents } from '@/enums/events';

import { NarrativeObjectType, OfferLocation, trackClickOutGraphql } from '@/helpers/offer-helper';
import {
	SnowplowClickoutContextGraphql,
	SnowplowContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { timestamp } from '@/helpers/date-helper';

import type { TitleOffers } from '@/interfaces/buybox';
import { OfferPresentationType } from '@/interfaces/titles';

import { ObjectType, Offer as OfferGraphql, Package, Scoring } from '@/@types/graphql-types';
import { MonetizationType, PresentationType, SraEventAction } from '@/@types/graphql-types';
import { ClickoutContentType } from '@/interfaces/snowplow/clickout-context';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { useApolloData } from '@/helpers/composables/useApolloData';
import { UsePromotion, allowedPromotionLanguages, usePromotion } from '@/helpers/composables/usePromotion';

import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import { useArbitrage } from '@/helpers/composables/useArbitrage';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';
import { NewBuyBoxView } from '@/stores/user.store';
import { EXCLUDED_PACKAGES } from '@/constants/buybox-excluded-packages.constant';

import { appleQualityTvCountries } from './AppleQualityTvRow/data';
import { CountriesToBeSorted, sortOffersByMonetizationPotential } from './utils/buy-box-helpers';

// NEW_BUYBOX_TV_SHOW_EXP_V1_2
import { NewBuyBoxTVShowExpV1_2Name } from '../experiments/NewBuyboxTVShowExpV1-2';
// NEW_BUYBOX_TV_SHOW_EXP_V1_2

const Constant = namespace('constant');
const Language = namespace('language');
const Routing = namespace('routing');
const User = namespace('user');
// NEW_BUYBOX_TV_SHOW_EXP_V1_2
const Experiment = namespace('experiment');
// NEW_BUYBOX_TV_SHOW_EXP_V1_2

@Component({
	name: 'BuyBox',
	components: {
		OfferReporter,
		BuyBoxFilter,
		ProviderIcon,
		BuyBoxRow,
		BuyBoxFastRow,
		BuyBoxNoOfferRow,
		PromotionRow,
		AppleQualityTvRow,
	},
	apollo: {
		offers: {
			query: GetTitleOffersDocument,
			variables(): GetTitleOffersQueryVariables {
				return this.queryVariables;
			},
			update(data: GetTitleOffersQuery) {
				if (data.node == null) return;

				if (
					data.node.__typename === 'Episode' ||
					data.node.__typename === 'Movie' ||
					data.node.__typename === 'Season' ||
					data.node.__typename === 'Show'
				) {
					const { buy, rent, fast, free, flatrate, offerCount, maxOfferUpdatedAt, bundles } =
						data.node as GetTitleOffersQuery['node'] & { __typename: 'Movie' };
					const result: TitleOffers = {
						stream: [],
						rent: rent as TitleOffers['rent'],
						buy: buy as TitleOffers['buy'],
						free: free as TitleOffers['free'],
						fast: fast as TitleOffers['fast'],
						cinema: [],
						bundles: bundles as TitleOffers['bundles'],
						offerCount,
						maxOfferUpdatedAt,
					};
					(flatrate as TitleOffers['cinema']).forEach(offer =>
						offer.monetizationType === MonetizationType.Cinema
							? result.cinema.push(offer)
							: result.stream.push(offer)
					);
					return result;
				}
			},
			watchLoading(isLoading) {
				if (!process.server) {
					!this.buyboxLoaded && this.onBuyboxLoadingChange(isLoading);
				}
			},
		},
	},
	setup({ title }) {
		const { inPromotionCatalogue, promotionOffer, promotionCatalogue } = usePromotion({
			title,
			offers: () => {
				const { free = [], stream } = useApolloData<TitleOffers>('offers')();
				return [...free, ...stream];
			},
		});

		const { isArbitrating } = useArbitrage();

		return { inPromotionCatalogue, promotionOffer, promotionCatalogue, isArbitrating };
	},
})
export default class BuyBox extends Mixins(BackendTrackingMixin) {
	buyboxLoaded = false;
	offers: TitleOffers = {
		stream: [],
		rent: [],
		buy: [],
		free: [],
		fast: [],
		cinema: [],
		bundles: [],
		offerCount: 0,
		maxOfferUpdatedAt: '',
	};

	// usePromotion composable exports
	declare inPromotionCatalogue: UsePromotion['inPromotionCatalogue'];
	declare promotionOffer: UsePromotion['promotionOffer'];
	declare promotionCatalogue: UsePromotion['promotionCatalogue'];
	declare isArbitrating: boolean;

	// TODO: remove all title* props across <BuyBox> usages
	@Prop({ required: true }) titleId: string;
	@Prop({ required: true }) titleObjectType: NarrativeObjectType; // base title objectType
	@Prop({ required: true }) titleObjectId: number; // base title id
	@Prop({ default: '' }) titleName: string;
	@Prop({ required: false }) title: TitleDetail;
	// You need to pass this prop when title is Season/Episode, because BuyBox component has functionality to set
	// title into Watchlist, but this mutation available only for Movie/Show. So basically when you set Season/Episode
	// to Watchlist we set whole Show to watchlist.
	@Prop({ default: null }) showDetails: {
		id: string;
		objectId: number;
		objectType: ObjectType;
		title: string;
	};
	@Prop({ default: false }) isTitleInWatchlist: boolean;
	@Prop({ default: () => ({}) }) titleScoring: Scoring;
	@Prop({ default: () => null }) seasonNumber: number | null;
	@Prop({ default: () => null }) episodeNumber: number | null;
	@Prop({ default: '' }) originalReleaseYear: number;
	@Prop({ default: null }) sponsoredAd: SponsoredAdFragment;
	@Prop({ default: false }) isBannerBuyBox: boolean;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	@Prop() buyBoxSwitchType: NewBuyBoxView;

	// Show filter bar
	@Prop({ default: false, type: Boolean }) showFilter!: boolean;

	// display as inline
	@Prop(Boolean) inline: boolean;

	// no header 'WATCH NOW'
	@Prop({ default: false }) noHeader: boolean;

	@Prop() defaultPresentationType: OfferPresentationType;
	@Prop({ default: false }) isInModal: boolean;

	// START AVOD
	@Prop({ default: false }) isAVODActive: boolean;
	// END AVOD

	// NEW_BUYBOX_TV_SHOW_EXP_V1_2
	@Prop({ default: false }) isEpisodeBuybox: boolean;
	@Prop({ default: false }) isPopup: boolean;

	@Experiment.Getter activeVariantsWithControlGroup: Record<string, string>;
	// NEW_BUYBOX_TV_SHOW_EXP_V1_2

	@Constant.Getter allProvidersById: Record<number, Package>;
	@Constant.Getter titleProviderCount: number;

	@Language.Getter declare language: string;
	@Language.Getter declare country: string;

	@Routing.State activeRoute: Route;

	@User.Getter buyboxPresentationType: OfferPresentationType | null;
	@User.State isPremium: boolean;
	@User.State jwId: string;
	@User.Getter isLoggedIn: boolean;
	@User.Action setNewBuyboxView: (view: NewBuyBoxView) => void;
	@User.Getter newBuyboxView: NewBuyBoxView;

	@User.Action setBuyboxPresentationType: (payload: { presentationType: OfferPresentationType | null }) => void;

	@Emit('onWatchlistToggle')
	onWatchlistToggle(createdAt: string | null) {
		return createdAt;
	}

	@Emit('buyboxLoading')
	onBuyboxLoadingChange(isLoading: boolean) {
		if (!isLoading) {
			this.buyboxLoaded = true;
		}

		return isLoading;
	}

	isMounted = false;
	mounted() {
		this.isMounted = true;
		// to update presentationType on Search page
		if (this.$route.query.monetization_types === 'free') {
			this.setBuyboxPresentationType({ presentationType: OfferPresentationType.FREE });
		}
	}

	get queryVariables(): GetTitleOffersQueryVariables {
		const presentationTypes = this.presentationTypeGraphql ? [this.presentationTypeGraphql] : undefined;

		return {
			nodeId: this.titleId,
			country: this.country,
			language: this.language,
			filterBuy: {
				monetizationTypes: [MonetizationType.Buy],
				bestOnly: true,
				presentationTypes,
			},

			filterFlatrate: {
				monetizationTypes: [
					MonetizationType.Flatrate,
					MonetizationType.FlatrateAndBuy,
					MonetizationType.Ads,
					MonetizationType.Free,
					MonetizationType.Cinema,
				],
				presentationTypes,
				bestOnly: true,
			},
			filterRent: {
				monetizationTypes: [MonetizationType.Rent],
				presentationTypes,
				bestOnly: true,
			},
			filterFree: {
				monetizationTypes: [MonetizationType.Ads, MonetizationType.Free],
				presentationTypes,
				bestOnly: true,
			},
		};
	}

	get presentationTypeGraphql() {
		// @ts-ignore
		const presentationTypeMap: Record<OfferPresentationType, PresentationType> = {
			[OfferPresentationType._4K]: PresentationType['4K'],
			[OfferPresentationType.HD]: PresentationType.Hd,
			[OfferPresentationType.SD]: PresentationType.Sd,
			[OfferPresentationType.DVD]: PresentationType.Dvd,
			[OfferPresentationType.BLURAY]: PresentationType.Bluray,
			[OfferPresentationType.CANVAS]: PresentationType.Canvas,
		};

		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		if (this.isShowExpEpisodeBuybox) {
			if (this.episodeBuyBoxPresentationType == null) return null;
			return presentationTypeMap[this.episodeBuyBoxPresentationType];
		}
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2

		if (this.buyboxPresentationType == null) return null;
		return presentationTypeMap[this.buyboxPresentationType];
	}

	get presentationTypes() {
		return {
			[PresentationType.Hd]: {
				text: 'HD',
				name: 'hd',
			},
			[PresentationType['4K']]: {
				text: '4K',
				name: 'hd',
			},
			[PresentationType.Dvd]: {
				text: 'DVD',
				name: 'dvd',
			},
			[PresentationType.Bluray]: {
				text: 'Blu-Ray',
				name: 'bluray',
			},
		};
	}

	get isFreeBuyBox() {
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		if (this.isShowExpEpisodeBuybox) return this.episodeBuyBoxPresentationType === OfferPresentationType.FREE;
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		return this.buyboxPresentationType === OfferPresentationType.FREE;
	}

	/** Excludes Free. The title does not have stream, rent, buy, or any other offers. */
	get hasNoNonFreeOffers() {
		const offerTypes = ['stream', 'rent', 'buy', 'fast', 'cinema'] as const;
		return offerTypes.reduce((acc, curr) => (acc += (this.filteredOffers?.[curr] ?? []).length), 0) === 0;
	}

	get hasOnlyCinemaOffers() {
		const cinemaOfferCount = this.filteredOffers.cinema.length;
		return cinemaOfferCount > 0 && cinemaOfferCount === this.filteredOffers.offerCount;
	}

	get showPromotionRow() {
		const rawLanguage = this.language.split('-')[0];
		return this.title && !this.isPremium && this.isFreeBuyBox && allowedPromotionLanguages.includes(rawLanguage);
	}

	/** Depending on filters "no offers" can mean different things. */
	get hasNoOffersToShow() {
		// this buybox filter is about Monetization, so here specifically we ask
		// if there are no "FREE" offers only
		if (this.isFreeBuyBox) {
			const hasNoFreeOffers = this.filteredOffers.free.length === 0;

			// if there is a promotion from a provider that does have an offer,
			// then techincally the promotion counts as an "offer".
			if (this.showPromotionRow) {
				return !this.inPromotionCatalogue && hasNoFreeOffers;
			}

			return hasNoFreeOffers;
		}

		return this.hasNoNonFreeOffers || this.hasOnlyCinemaOffers;
	}

	get lastUpdated() {
		return this.filteredOffers.maxOfferUpdatedAt;
	}

	get isTitleDetailPage() {
		return this.activeRoute.name?.includes('app.detail');
	}

	get timestampText() {
		const updated = timestamp(
			this.lastUpdated,
			{ year: 'numeric', month: 'long', day: 'numeric' },
			this.country,
			this.language
		);
		return this.$t('WEBAPP_BUYBOX_UPDATED_TIMESTAMP', {
			services_total: this.titleProviderCount,
			date: updated.date,
			time: updated.time,
		});
	}

	get sponsoredRecommendationPackageId() {
		return this.sponsoredAd?.campaign?.watchNowOffer.package.packageId;
	}

	get sponsoredRecommendationCohort() {
		return this.sponsoredAd?.holdoutGroup ? 0 : 1;
	}

	get filteredOffers() {
		// Start with the full list of offers
		let _offers = this.offers;

		// Filtering out TVOD providers we don't have a partnership with
		const { offerCount, maxOfferUpdatedAt, bundles, ...allOffers } = _offers;
		const filteredOffers = Object.fromEntries(
			Object.entries(allOffers).map(([key, value]) => [
				key,
				value.filter(offer => {
					return !EXCLUDED_PACKAGES.value.includes(offer.package.packageId);
				}),
			])
		);
		_offers = {
			...filteredOffers,
			bundles,
			offerCount,
			maxOfferUpdatedAt,
		} as TitleOffers;

		if (!this.isBannerBuyBox) return _offers;

		// Return filtered offers
		return sortOffersByMonetizationPotential(_offers, this.country as CountriesToBeSorted);
	}

	get fastOffers() {
		// Exclude Offers that are no longer active.
		return this.filteredOffers.fast.filter(el => new Date(el.availableToTime) > new Date());
	}

	get showFastRow() {
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		if (this.isShowExpEpisodeBuybox) return this.isFreeBuyBox || this.episodeBuyBoxPresentationType === null;
		// NEW_BUYBOX_TV_SHOW_EXP_V1_2
		return this.isFreeBuyBox || this.buyboxPresentationType === null;
	}

	get trackingContexts() {
		const titleObjectId = this.showDetails ? this.showDetails.objectId : this.titleObjectId;
		const titleObjectType = this.showDetails ? this.showDetails.objectType : this.titleObjectType;
		const titleContext = new SnowplowTitleContextGraphql(
			titleObjectId,
			titleObjectType,
			this.seasonNumber,
			this.episodeNumber
		);

		return (this.additionalContexts || []).concat([titleContext]);
	}

	clickOut(payload: { buyboxOffer: OfferGraphql; isLeavingIconShown: boolean; offerLocation?: OfferLocation }) {
		let _offer = payload.buyboxOffer;
		const titleObjectId = this.showDetails ? this.showDetails.objectId : this.titleObjectId;
		const titleObjectType = this.showDetails ? this.showDetails.objectType : this.titleObjectType;

		if (payload.buyboxOffer.package.packageId === this.sponsoredRecommendationPackageId) {
			let contexts: SnowplowContext[] = [];
			if (payload.buyboxOffer) {
				const clickoutContext = SnowplowClickoutContextGraphql.fromProviderOffer(payload.buyboxOffer);
				contexts = this.trackingContexts;
				contexts.push(clickoutContext);
			}

			TrackingHelper.trackEvent(
				ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
				{
					action: 'buybox_clickout',
					label: `${this.titleId}_${this.sponsoredRecommendationPackageId}`,
					property: 'buybox',
					value: this.sponsoredRecommendationCohort,
				},
				contexts
			);

			this.$backendTracking.trackSraEvent(SraEventAction.BuyboxClickout, this.sponsoredAd, this.titleId);
		}

		//TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		const clickoutContext = SnowplowClickoutContextGraphql.fromProviderOffer(
			payload.buyboxOffer,
			payload?.isLeavingIconShown ? ClickoutContentType.LeavingSoon : undefined
		);

		trackTitleDetailRedesignNewBuyBoxExp({
			action: 'click',
			label: this.getExperimentClickoutLabel(payload.offerLocation),
			contexts: [clickoutContext, ...this.additionalContexts],
			value: this.getExperimentOfferLocation(payload.offerLocation),
		});

		if (this.isBannerBuyBox) {
			payload.offerLocation = OfferLocation.BANNER_BUYBOX;
		}

		trackClickOutGraphql({
			offer: _offer,
			objectType: titleObjectType,
			objectId: titleObjectId,
			seasonNumber: this.seasonNumber,
			episodeNumber: this.episodeNumber,
			offerLocation: payload.offerLocation ?? OfferLocation.REGULAR_BUYBOX,
			additionalContexts: this.additionalContexts,
			contentType: payload?.isLeavingIconShown ? ClickoutContentType.LeavingSoon : undefined,
		});
	}

	// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

	// NEW_BUYBOX_TV_SHOW_EXP_V1_2

	episodeBuyBoxPresentationType: OfferPresentationType | null = null;

	get isNewBuyboxShowActiveVariant() {
		return false;
	}

	get isShowExpEpisodeBuybox() {
		return this.isEpisodeBuybox && this.isNewBuyboxShowActiveVariant;
	}

	getExperimentOfferLocation(offerLocation?: OfferLocation) {
		if (offerLocation === OfferLocation.FREE_TRIAL) return offerLocation;
		if (this.isBannerBuyBox) return OfferLocation.BANNER_BUYBOX;
		if (this.isEpisodeBuybox) return OfferLocation.EPISODE_BUYBOX;

		return offerLocation ?? OfferLocation.REGULAR_BUYBOX;
	}

	getExperimentClickoutLabel(offerLocation?: OfferLocation) {
		let label = 'clickout_buybox';

		if (this.isBannerBuyBox) label = 'clickout_banner_buybox';
		else if (this.isEpisodeBuybox) label = this.isPopup ? 'clickout_episode_popup' : 'clickout_episode';

		return offerLocation === OfferLocation.FREE_TRIAL ? `free_trial_${label}` : label;
	}

	handleShowExpEpisodeFilterChange(presentation: OfferPresentationType) {
		this.setEpisodeBuyboxPresentationType(presentation);
	}

	setEpisodeBuyboxPresentationType(presentationType: OfferPresentationType | null) {
		this.episodeBuyBoxPresentationType = presentationType;
	}

	// NEW_BUYBOX_TV_SHOW_EXP_V1_2

	get appleQualityTvActive() {
		return (
			!this.isLoggedIn &&
			this.promotionOffer?.id === 'PROMOTION_OFFER' &&
			appleQualityTvCountries.includes(this.country) &&
			this.promotionOffer?.package.packageId === 350 &&
			(!this.isMounted || (this.isMounted && !this.isArbitrating)) &&
			!this.inline
		);
	}
}
