
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import CountryListItem from '@/components/modals/country-list/CountryListItem.vue';

import type { WebLocale } from '@/enums/web-locale';

import { UrlMetadataResponse } from '@/interfaces/responses/url-metadata';

import SearchBar from '@/components/search/SearchBar.vue';
import { WEB_LOCALES } from '@/constants/web-locales.constant';
import { Locale } from '@/helpers/locale-helper';
import { SnowplowContext, SnowplowModuleContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

const Language = namespace('language');
const Routing = namespace('routing');

@Component({
	name: 'CountryList',
	components: { CountryListItem, SearchBar },
})
export default class CountryList extends Vue {
	search: string = '';

	@Prop({ default: () => [] }) additionalContexts: (SnowplowModuleContext | SnowplowContext)[];

	@Language.Action setWebLocale: (webLocale: WebLocale) => Promise<void>;
	@Language.Action loadLocales: () => void;
	@Language.Action setLanguageWhenUserDidNot: (language: string) => void;

	@Language.State detectedWebLocale: WebLocale | null;
	@Language.State locales: Locale[];
	@Language.State webLocale: WebLocale;

	@Routing.Getter urlMetadata: UrlMetadataResponse;

	async created() {
		this.loadLocales();
	}

	get titleLocales() {
		// instead of just urlMetadata.href_lang_tags we also include hreflang tags that have noindex
		return this.urlMetadata.allHrefLangTags!.map(lt => ({
			country: lt.href.slice(1, 3),
			href: lt.href,
		}));
	}

	get titleLocalesArray() {
		return this.titleLocales.map(titleLocale => titleLocale.country);
	}

	get webLocales(): WebLocale[] {
		return this.locales
			.filter(locale => {
				return this.titleLocalesArray.includes(locale.webLocale);
			})
			.filter(locale => {
				if (!this.search) {
					return true;
				}
				// find in all languages something that matches the search input
				return (Object.values(locale.country_names).map(c => c.toLowerCase()) || [])
					.concat(locale.country.toLowerCase())
					.some(item => item.indexOf(this.search.toLowerCase()) !== -1);
			})
			.sort((a, b) => {
				const countryTranlatedNameA = this.$t(`WEBAPP_COUNTRY_${a.webLocale.toUpperCase()}`);
				const countryTranlatedNameB = this.$t(`WEBAPP_COUNTRY_${b.webLocale.toUpperCase()}`);
				// sort alphabetically
				if (countryTranlatedNameA < countryTranlatedNameB) {
					return -1;
				}
				if (countryTranlatedNameA > countryTranlatedNameB) {
					return 1;
				}
				return 0;
			})
			.map(locale => locale.webLocale);
	}

	get availableWebLocale() {
		const availableLocales = this.locales.map((locale: any) => locale.webLocale) || [];
		if (availableLocales.indexOf(this.webLocale) !== -1) {
			return this.webLocale;
		}
		return '';
	}

	async selectWebLocale(webLocale: WebLocale) {
		if (webLocale === this.webLocale) return;

		const localisedTitlePage = this.titleLocales.find(tl => tl.country === webLocale);
		if (localisedTitlePage) {
			const localeString = WEB_LOCALES[webLocale] || '';
			const language = localeString.substring(0, 2);
			await this.setWebLocale(webLocale);
			await this.setLanguageWhenUserDidNot(language);

			TrackingHelper.trackEvent(
				'userinteraction',
				{
					action: 'country_selector_toggled',
					property: webLocale,
				},
				this.additionalContexts
			);
			await this.$router.push({ path: localisedTitlePage.href });
			await this.$nextTick();

			this.$emit('selected-country', webLocale);
		}
	}

	onClearSearch() {
		this.search = '';
	}
}
